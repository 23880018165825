import { Button, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../app/hooks";
import { EMAIL_REGEX } from "../common/commonApi";
import { forgotAsync } from "./authSlice";
import FormComponent from "./FormComponent";

export default () => {

  const dispatch = useAppDispatch();
  const [email, setInputEmail] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleChange = (e: any) => {
    setInputEmail(e.target.value);
  }

  const handleSubmit = () => {
    if (email == null) {
      console.error("email is empty");
      return;
    }
    dispatch(forgotAsync({ email }))
    navigate("/front/change-forgotten-password");
  }
  const emailIsTouched = email != null;
  const emailIsNotValid = emailIsTouched && !EMAIL_REGEX.test(email as string);

  return (
    <FormComponent formName="Forgot Password">
      <Typography variant="subtitle1">
        Enter your email
      </Typography>
      <TextField
        error={emailIsNotValid}
        margin="normal"
        variant="outlined"
        placeholder="Email"
        value={email}
        onChange={handleChange}
        name="email"
        helperText={emailIsNotValid && "Invalid email"}
      />
      <Button
        sx={{ marginTop: 3, borderRadius: 3 }}
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={!emailIsTouched ||  emailIsNotValid }
      >
        Submit
      </Button>
      <Button
        onClick={() => navigate("/front/login")}
        sx={{ marginTop: 3, borderRadius: 3 }}
      >
        Go to Login
      </Button>
    </FormComponent>
  );

}

