import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import reportWebVitals from './reportWebVitals';
import './index.css';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import LoginForm from './features/auth/LoginForm';
import SignUpForm from './features/auth/SignUpForm';
import ForgotPasswordForm from './features/auth/ForgotPasswordForm';
import SignUpConfCodeForm from './features/auth/SignUpConfCodeForm';
import ChangeForgottenPasswordForm from './features/auth/ChangeForgottenPasswordForm';
import { PatternsPage } from './features/patterns/PatternsPage';
import { Alert, Box, CircularProgress, createTheme, Snackbar, ThemeProvider } from '@mui/material';
import { useAppDispatch, useAppSelector } from './app/hooks';
import { clearSnack, isLoggedInAsync, selectAuthStatus, selectSnack } from './features/auth/authSlice';
import { ProtectedRoute } from './features/common/Protected';
import { NotProtectodRoute } from './features/common/NotProtectedRoute';

const container = document.getElementById('root')!;
const root = createRoot(container);


const LoginStatusFetcher = ({ children }: { children: JSX.Element }) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(isLoggedInAsync());
  }, [])
  return children;
}

/**
we use /front prefix to avoid using passing full route to the backend.
Backend will return same index.html page to all routes started with /font,
so such routes are addressed by react-router
*/
const router = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to="/front/login" replace={true} />
  },
  {
    path: "/front/protected",
    element: <ProtectedRoute />,
    children: [
      {
        path: "patterns",
        element: <PatternsPage />
      }
    ]
  },
  {
    path: "/front",
    element: <NotProtectodRoute />,
    children: [
      {
        path: "login",
        element: (
          <LoginForm />
        )
      },
      {
        path: "signup",
        element: (
          <SignUpForm />
        )
      },
      {
        path: "forgotpassword",
        element: (
          <ForgotPasswordForm />
        )
      },
      {
        path: "signup-conf-code",
        element: (
          <SignUpConfCodeForm />
        )
      },
      {
        path: "change-forgotten-password",
        element: (
          <ChangeForgottenPasswordForm />
        )
      },

    ]
  }
]);


const theme = createTheme({
  typography: {
    button: {
      textTransform: 'none'
    }
  }
});

function CommonElements({ children }: { children: JSX.Element }) {
  const status = useAppSelector(selectAuthStatus);
  const snack = useAppSelector(selectSnack);
  const dispatch = useAppDispatch();

  let snackBar = null;
  if (snack != null) {
    const { message, sevirity } = snack;
    snackBar = (
      <Snackbar open={true} autoHideDuration={6000} onClose={() => dispatch(clearSnack())}>
        <Alert severity={sevirity} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    );
  }

  const childrenWithSnack = (
    <>
      {children},
      {snackBar}
    </>
  );

  if (status === 'loading') {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );
  } else {
    return childrenWithSnack;
  }
}

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <LoginStatusFetcher>
          <CommonElements>
            <RouterProvider router={router} />
          </CommonElements>
        </LoginStatusFetcher>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

