import { Button, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { selectEmail, selectEmailNonConfirmed, verifyAsync } from "./authSlice"
import FormComponent from "./FormComponent";


export default () => {
  const emailNonConfirmed = useAppSelector(selectEmailNonConfirmed);

  const dispatch = useAppDispatch();
  const [code, setCode] = useState<string | null>(null);

  const navigate = useNavigate();

  if (emailNonConfirmed == null) {
    return <Navigate to="/front/signup" />
  }

  const handleChange = (e: any) => {
    setCode(e.target.value);
  }

  const codeIsTouched = code != null;
  const codeIsEmpty = codeIsTouched && (code as string).length === 0;

  const handleSubmit = () => {
    if (!codeIsTouched) {
      console.error("code is empty");
      return
    }
    const payload = {
      email: emailNonConfirmed,
      code
    };
    dispatch(verifyAsync(payload));
    navigate('/front/login');
  }
  return (
    <FormComponent formName="">
      <Typography variant="subtitle1">
        Confirmation code was sent you your email {emailNonConfirmed}
      </Typography>
      <TextField
        error={codeIsEmpty}
        margin="normal"
        variant="outlined"
        placeholder="code"
        value={code}
        onChange={handleChange}
        helperText={codeIsEmpty && "Code shouldn't be empty"}
        name="code"
      />
      <Button
        sx={{ marginTop: 3, borderRadius: 3 }}
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={!codeIsTouched || codeIsEmpty}
      >
        Submit
      </Button>
    </FormComponent>
  );

}
