
import { Button, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { confirmAsync, selectEmailNonConfirmed } from "./authSlice";
import FormComponent from "./FormComponent";

export default () => {
  const emailNonConfirmed = useAppSelector(selectEmailNonConfirmed);

  const dispatch = useAppDispatch();
  const [inputs, setInputs] = useState<{
    password: string | null,
    confirmPassword: string | null,
    code: string | null
  }>({
    password: null,
    confirmPassword: null,
    code: null
  });
  const navigate = useNavigate();

  if (emailNonConfirmed == null) {
    return (<Navigate to="/front/login" />)
  }

  const handleChange = (e: any) => {
    setInputs((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
  }
  const passwordIsTouched = inputs.password != null;
  const confirmPasswordIsTouched = inputs.confirmPassword != null;
  const codeIsTouched = inputs.code != null;

  const codeIsEmpty = codeIsTouched && (inputs.code as string).length === 0;
  const passwordIsNotValid = passwordIsTouched && (inputs.password as string).length < 6;
  const confirmPasswordIsNotValid = confirmPasswordIsTouched && inputs.confirmPassword !== inputs.password;

  const handleSubmit = () => {
    if (!passwordIsTouched || !confirmPasswordIsTouched || !codeIsTouched) {
      console.error('input is empty');
      return
    }
    const payload = {
      username: emailNonConfirmed as string,
      code: inputs.code as string,
      newPassword: inputs.password as string
    }
    dispatch(confirmAsync(payload))
    navigate("/front/login")
  }
  return (
    <FormComponent formName="Set password">
      <Typography variant="subtitle1">
        Enter code submitted to {emailNonConfirmed}
      </Typography>
      <TextField
        error={codeIsEmpty}
        margin="normal"
        variant="outlined"
        placeholder="Code"
        value={inputs.code}
        onChange={handleChange}
        name="code"
        helperText={codeIsEmpty && "Code shouldn't be empty"}
      />
      <TextField
        error={passwordIsNotValid}
        margin="normal"
        variant="outlined"
        placeholder="New Password"
        value={inputs.password}
        onChange={handleChange}
        name="password"
        type="password"
        helperText={passwordIsNotValid && "should be at least 6 symbols"}
      />
      <TextField
        error={confirmPasswordIsNotValid}
        margin="normal"
        variant="outlined"
        placeholder="Confirm Password"
        value={inputs.confirmPassword}
        onChange={handleChange}
        name="confirmPassword"
        type="password"
        helperText={confirmPasswordIsNotValid && "passwords are not equal"}
      />
      <Button
        sx={{ marginTop: 3, borderRadius: 3 }}
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={!codeIsTouched|| !passwordIsTouched || !confirmPasswordIsTouched || codeIsEmpty || passwordIsNotValid || confirmPasswordIsNotValid}
      >
        Submit
      </Button>
    </FormComponent>
  );

}