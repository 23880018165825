"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ResponseCode = exports.PatternScope = void 0;
exports.PatternScope = {
    GLOBAL: 'global',
    USER: 'user'
};
exports.ResponseCode = {
    OK: "ok",
    USER_NOT_CONFIRMED: "user_not_confirmed",
    NOT_AUTHORIZED: "not_authorized",
    OTHER: "other",
    INVALID_CREDENTIALS: "invalid_credentials"
};
