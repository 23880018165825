import { Add, Edit } from "@mui/icons-material"
import { Button, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import DeleteIcon from '@mui/icons-material/Delete';
import { deletePatternAsync, fetchPatternsAsync, openPatternsForm, PatternTableRow, selectRows, setPatternFormData } from "../auth/authSlice";


export function PatternsTable(): JSX.Element {
    const rows = useAppSelector(selectRows);
    const sortedRows = (rows ?? []).slice().sort((a,b) => (Number(a.preority) > Number(b.preority))?-1:1);
    const dispatch = useAppDispatch();
    useEffect(() => {
      rows == null && dispatch(fetchPatternsAsync())
    }, []);
    const buttons = (row: PatternTableRow) => {
      const deletePayload = {
        patternScope: row.patternScope,
        patternId: row.patternId
      }
      const handleOnEditClick = () => {
        dispatch(setPatternFormData(row));
        dispatch(openPatternsForm());
      }

      return (
        <>
          <IconButton 
            aria-label="edit"
            onClick={handleOnEditClick}
          >
            <Edit />
          </IconButton>
          <IconButton 
            aria-label="delete" 
            onClick={() => dispatch(deletePatternAsync(deletePayload))}
          >
            <DeleteIcon />
          </IconButton>
        </>
      );

    }
    return (<TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Preority</TableCell>
            <TableCell align="left">Pattern Scope</TableCell>
            <TableCell align="left">Description</TableCell>
            <TableCell align="left">Regex pattern</TableCell>
            <TableCell align="left">Query pattern</TableCell>
            <TableCell align="left">URI</TableCell>
            <TableCell align="left">
            <Button variant="outlined" onClick={() => dispatch(openPatternsForm())} startIcon={<Add />}>
              Add pattern
            </Button>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedRows.map((row) => (
            <TableRow
              key={row.patternId}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left">{row.preority}</TableCell>
              <TableCell component="th" scope="row">
                {row.patternScope}
              </TableCell>
              <TableCell align="left">{row.description}</TableCell>
              <TableCell align="left">{row.regexPattern}</TableCell>
              <TableCell align="left">{row.queryPattern}</TableCell>
              <TableCell align="left">{row.uri}</TableCell>
              <TableCell align="left">{buttons(row)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>)
}



export {}