import { PatternsForm } from "./PatternsForm";
import { PatternsTable } from "./PatternsTable";

export function PatternsPage() {
  return (
      <>
        <PatternsTable />
        <PatternsForm />
      </>
  );
}