import { TResponseCode } from "common/bin/types";


// signup case doens't work 

export async function signup(props: { email: string, password: string }): Promise<{username: string}> {
  const resp = await fetch('/api/signup', {
    method: 'POST',
    body: JSON.stringify(props),
  });
  if(resp.status !== 200) {
    throw new Error("signup api failed")
  }
  const {username} = await resp.json();
  return {username}
}

export async function verify({ email, code }: { email: string, code: string }): Promise<void> {
  const payload = {
    username: email,
    code
  }
  const { status } = await fetch('/api/verify', {
    method: 'POST',
    body: JSON.stringify(payload),
  });
  
  if ( status !== 200) {
    throw new Error("unable to verify email");
  };
}


export async function login(props: { email: string, password: string }): Promise<{ code: TResponseCode, username: string }> {
  const resp = await fetch('/api/login', {
    method: 'POST',
    body: JSON.stringify(props),
  });
  if (resp.status !== 200) {
    throw new Error("unable to login");
  }
  const { code, username } = await resp.json();
  return { code: code as TResponseCode, username };
}

/**
 if user is loggedin, return username(email), undefined otherwise
*/
export async function isLoggedIn(): Promise<string | undefined> {
  const resp = await fetch(
    '/api/isloggedin',
  );
  if (resp.status !== 200) {
    return undefined;
  }
  const { username } = await resp.json()
  return username;
}

export async function logout(): Promise<boolean> {
  const resp = await fetch('/api/logout', {
    method: 'POST',
  });
  return resp.status === 200;
}

export async function forgot({ email }: { email: string }): Promise<{ username: string }> {
  const payload = {
    username: email
  }
  const resp = await fetch('/api/forgot', {
    method: 'POST',
    body: JSON.stringify(payload)
  })
  if (resp.status !== 200) {
    throw new Error("unable to resubmit password");
  }
  const { username } = await resp.json();
  return { username }

}


export async function confirmCode(props: { username: string, code: string, newPassword: string }): Promise<void> {
  const resp = await fetch('/api/confirm', {
    method: 'POST',
    body: JSON.stringify(props)
  })
  if( resp.status !== 200) {
    throw new Error("Unable to submit confirmatino code")
  }
}
