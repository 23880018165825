// Auth will be here

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ResponseCode } from "common/bin/types";
import { RootState } from "../../app/store";
import { createPattern, deletePattern, fetchPatterns, updatePattern } from "../patterns/patternsApi";
import { confirmCode, forgot, isLoggedIn, login, logout, signup, verify } from "./authAPI";


export type PatternTableRow = {
  patternId: string,
  patternScope: string,
  preority: string,
  description: string,
  regexPattern: string,
  queryPattern: string,
  uri: string,
}

export type PatternFormData = Omit<PatternTableRow, 'patternId'> & { patternId?: string };

type TSevirity = 'error' | 'warning' | 'info' | 'success';

type TInfoPayload = {
  message: string,
  sevirity: TSevirity
};

export interface AuthState {
  email: string | undefined,
  emailNonConfirmed: string | null,
  patternFormData: PatternFormData,
  patternsFormIsOpen: boolean,
  rows: PatternTableRow[] | null,
  status: 'idle' | 'loading' | 'failed',
  snack: null | TInfoPayload
}

const initialState: AuthState = {
  email: undefined,
  emailNonConfirmed: null,
  patternFormData: {
    patternScope: 'user',
    preority: "50",
    regexPattern: "",
    queryPattern: "",
    uri: "",
    description: "",
  },
  patternsFormIsOpen: false,
  rows: null,
  status: 'idle',
  snack: null
}

export const signupAsync = createAsyncThunk('auth/signup', signup);
export const verifyAsync = createAsyncThunk('auth/verify', verify);
export const loginAsync = createAsyncThunk('auth/login', login);
export const isLoggedInAsync = createAsyncThunk('auth/isLoggedIn', isLoggedIn);
export const logoutAsync = createAsyncThunk('auth/logout', logout);
export const forgotAsync = createAsyncThunk('auth/forgot', forgot);
export const confirmAsync = createAsyncThunk('auth/confirm', confirmCode);
export const fetchPatternsAsync = createAsyncThunk('patterns/fetchPatterns', fetchPatterns)
export const createPatternAsync = createAsyncThunk('patterns/createPattern', createPattern);
export const updatePatternAsync = createAsyncThunk('patterns/updatePattern', updatePattern);
export const deletePatternAsync = createAsyncThunk('patterns/deletePattern', deletePattern);

type Email = string;

const loadingCase = (state: AuthState) => {
  state.status = 'loading';
}

const failedCase = (state: AuthState) => {
  state.status = 'failed';
}

const fulfilledCase = (state: AuthState) => {
  state.status = 'idle';
}

const fetchPatternsFulfilledCase = (state: AuthState, action: PayloadAction<PatternTableRow[], string, {
  arg: any;
  requestId: string;
  requestStatus: "fulfilled";
}, never>) => {
  state.status = 'idle';
  state.rows = action.payload;
}

const setSnack = (state: AuthState, message: string, sevirity: TSevirity) => {
  const payload: TInfoPayload = {
    message,
    sevirity
  };
  state.snack = payload;
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setEmail: (state, action: PayloadAction<Email>) => {
      state.email = action.payload
    },
    openPatternsForm: (state) => {
      state.patternsFormIsOpen = true;
    },
    closePatternsForm: (state) => {
      state.patternsFormIsOpen = false;
    },
    setPatternFormData: (state, action: PayloadAction<PatternFormData>) => {
      state.patternFormData = action.payload;
    },
    clearSnack: (state: AuthState) => {
      state.snack = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(confirmAsync.pending, loadingCase)
      .addCase(confirmAsync.fulfilled, (state) => {
        fulfilledCase(state);
        state.emailNonConfirmed = null;
        setSnack(state, "Email confirmed", "success")
      })
      .addCase(confirmAsync.rejected, (state) => {
        failedCase(state);
        setSnack(state, "Unable to confirm email", "error")
      })

      .addCase(forgotAsync.pending, loadingCase)
      .addCase(forgotAsync.fulfilled, (state, action) => {
        fulfilledCase(state);
        state.emailNonConfirmed = action.payload.username;
      })
      .addCase(forgotAsync.rejected, failedCase)

      .addCase(signupAsync.pending, loadingCase)
      .addCase(signupAsync.fulfilled, (state, action) => {
        fulfilledCase(state);
        // setSnack(state, "Signed up", "success")
        state.emailNonConfirmed = action.payload.username;
      })
      .addCase(signupAsync.rejected, (state) => {
        failedCase(state);
        setSnack(state, "Unable to sign up", "error")
      })

      .addCase(verifyAsync.pending, loadingCase)
      .addCase(verifyAsync.fulfilled, (state) => {
        fulfilledCase(state);
        state.emailNonConfirmed = null;
        setSnack(state, "Email confirmed", "success")
      })
      .addCase(verifyAsync.rejected, (state) => {
        failedCase(state);
        setSnack(state, "Unable to confirm email", "error");
      })

      .addCase(loginAsync.pending, loadingCase)
      .addCase(loginAsync.fulfilled, (state, action) => {
        const { username, code } = action.payload;
        if (code === ResponseCode.OK) {
          state.email = username;
          setSnack(state, "Sucessflly logged in", 'success');
        } else if (code === ResponseCode.USER_NOT_CONFIRMED) {
          setSnack(state, "Email not confirmed", 'warning');
          state.emailNonConfirmed = username;
        } else {
          setSnack(state, "Unable to login", 'error');
          state.status = 'failed';
          return;
        }
        fulfilledCase(state)
      })
      .addCase(loginAsync.rejected, (state) => {
        failedCase(state);
        setSnack(state, "Unable to login", 'error');
      })

      .addCase(isLoggedInAsync.pending, loadingCase)
      .addCase(isLoggedInAsync.fulfilled, (state, action) => {
        const maybeUsename = action.payload;
        state.email = maybeUsename;
        fulfilledCase(state);
      })
      .addCase(isLoggedInAsync.rejected, failedCase)

      .addCase(logoutAsync.pending, loadingCase)
      .addCase(logoutAsync.fulfilled, (state) => {
        //todo: patterns should be in initial state
        state.email = undefined;
        state.rows = null;
        fulfilledCase(state);
      })
      .addCase(logoutAsync.rejected, failedCase)

      .addCase(fetchPatternsAsync.pending, loadingCase)
      .addCase(fetchPatternsAsync.fulfilled, fetchPatternsFulfilledCase)
      .addCase(fetchPatternsAsync.rejected, failedCase)

      .addCase(createPatternAsync.pending, loadingCase)
      .addCase(createPatternAsync.fulfilled, fetchPatternsFulfilledCase)
      .addCase(createPatternAsync.rejected, failedCase)

      .addCase(updatePatternAsync.pending, loadingCase)
      .addCase(updatePatternAsync.fulfilled, fetchPatternsFulfilledCase)
      .addCase(updatePatternAsync.rejected, failedCase)

      .addCase(deletePatternAsync.pending, loadingCase)
      .addCase(deletePatternAsync.fulfilled, fetchPatternsFulfilledCase)
      .addCase(deletePatternAsync.rejected, failedCase)
  }
})

export const { setEmail, openPatternsForm, closePatternsForm, setPatternFormData, clearSnack } = authSlice.actions;

export const selectAuthStatus = (state: RootState) => state.auth.status;
export const selectEmail = (state: RootState) => state.auth.email;
export const selectEmailNonConfirmed = (state: RootState) => state.auth.emailNonConfirmed;
export const selectPatternFormData = (state: RootState) => state.auth.patternFormData
export const selectPatternsFormIsOpen = (state: RootState) => state.auth.patternsFormIsOpen;
export const selectPatternsStatus = (state: RootState) => state.auth.status;
export const selectRows = (state: RootState) => state.auth.rows;
export const selectSnack = (state: RootState) => state.auth.snack;

export default authSlice.reducer;
