import { Button, TextField } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../app/hooks";
import { EMAIL_REGEX } from "../common/commonApi";
import { signupAsync } from "./authSlice";
import FormComponent from "./FormComponent";



export default () => {
  const [inputs, setInputs] = useState<{
    email: string | null,
    password: string | null,
    confirmPassword: string | null
  }>({
    email: null,
    password: null,
    confirmPassword: null,
  });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();


  const handleChange = (e: any) => {
    setInputs((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
  }
  const emailIsToched = inputs.email != null;
  const passwordIsToched = inputs.password != null;
  const confirmPasswordIsTouched = inputs.confirmPassword != null;
  
  const handleSignUp = () => {
    if (!emailIsToched || !passwordIsToched || !confirmPasswordIsTouched) {
      console.error("Input is empty");
      return
    }
    dispatch(signupAsync({ email: inputs.email as string, password: inputs.password as string}));
    navigate("/front/signup-conf-code");
  }

  const emailIsNotValid = emailIsToched && !EMAIL_REGEX.test(inputs.email as string);
  const passwordIsNotValid = passwordIsToched && (inputs.password as string).length < 6;
  const confirmPasswordIsNotValid = confirmPasswordIsTouched && inputs.confirmPassword !== inputs.password;

  return (
    <FormComponent formName={"Signup" }>
      <TextField
        error={emailIsNotValid}
        margin="normal" 
        variant="outlined" 
        placeholder="Email" 
        value={inputs.email} 
        onChange={handleChange} 
        name="email"
        helperText={emailIsNotValid && "Invalid email"}
      />
      <TextField 
        error={passwordIsNotValid}
        margin="normal" 
        variant="outlined" 
        placeholder="Password" 
        value={inputs.password} 
        onChange={handleChange} 
        name="password" 
        type="password" 
        helperText={passwordIsNotValid && "should be at least 6 symbols"}
      />
      <TextField 
        error={confirmPasswordIsNotValid}
        margin="normal" 
        variant="outlined" 
        placeholder="Configm Password" 
        value={inputs.confirmPassword} 
        onChange={handleChange} 
        name="confirmPassword" 
        type="password" 
        helperText={confirmPasswordIsNotValid && "passwords are not equal"}
      />
      <Button
        sx={{ marginTop: 3, borderRadius: 3 }}
        variant="contained"
        color="primary"
        onClick={handleSignUp}
        disabled={!emailIsToched || !passwordIsToched || !confirmPasswordIsTouched || emailIsNotValid || passwordIsNotValid || confirmPasswordIsNotValid}
      >
        Signup 
      </Button>
      <Button
        onClick={() => navigate("/front/login")}
        sx={{ marginTop: 3, borderRadius: 3 }}
      >
        Go to Login
      </Button>
    </FormComponent>
  );

}
