import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closePatternsForm, createPatternAsync, selectPatternFormData, selectPatternsFormIsOpen, updatePatternAsync } from "../auth/authSlice";

export function PatternsForm(){
    const patternFormData = useAppSelector(selectPatternFormData);
    const patternId = patternFormData.patternId;
    const isEditForm = patternFormData.patternId != null;

    const [inputs, setInputs] = useState(patternFormData);

    useEffect(() => {
      setInputs(patternFormData);
    }, [patternFormData])

    const handleChange = (e: any) => {
        setInputs((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }));
    }
    const dispatch = useAppDispatch();
    const patternsFormIsOpen = useAppSelector(selectPatternsFormIsOpen);

    const handleSave = () => {
      if (isEditForm) {
        dispatch(updatePatternAsync({...inputs, patternId: patternId as string}));
      } else {
        dispatch(createPatternAsync(inputs));
      }
      dispatch(closePatternsForm());
    }

    return (
      <Dialog open={patternsFormIsOpen}>
          <DialogTitle>Create Pattern</DialogTitle>
          <DialogContent sx={{ m: 1, minWidth: 250 }}>
            <Stack spacing={2}>
              <Grid container>
                <Grid xs={5} item={true}>
                  <FormControl 
                    variant="standard" 
                    fullWidth             
                    margin="dense"
                  >
                    <InputLabel id="pattern-scope-label">Pattern Scope</InputLabel>
                    <Select
                      labelId="pattern-scope-label"
                      id="pattern-scope-select"
                      name="patternScope"
                      value={inputs.patternScope}
                      onChange={handleChange}
                      label="Pattern Scope"
                    >
                      <MenuItem value={'global'}>Global</MenuItem>
                      <MenuItem value={'user'}>User</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid xs={2} item={true}></Grid>
                <Grid xs={5} item={true}>
                  <TextField
                    margin="dense"
                    id="preority"
                    name="preority"
                    label="Preority"
                    variant="standard"
                    value={inputs.preority}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
              <TextField
                margin="dense"
                id="regex-pattern"
                label="Regex pattern"
                name="regexPattern"
                fullWidth
                variant="standard"
                value={inputs.regexPattern}
                onChange={handleChange}
              />
              <TextField
                margin="dense"
                id="query-pattern"
                label="Query pattern"
                name="queryPattern"
                fullWidth
                variant="standard"
                value={inputs.queryPattern}
                onChange={handleChange}
              />
              <TextField
                margin="dense"
                id="uri"
                label="URI"
                name="uri"
                fullWidth
                variant="standard"
                value={inputs.uri}
                onChange={handleChange}
              />
              <TextField
                margin="dense"
                id="description"
                label="Description"
                name="description"
                fullWidth
                variant="standard"
                multiline
                maxRows={4}
                value={inputs.description}
                onChange={handleChange}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={() => dispatch(closePatternsForm())}>Cancel</Button>
            <Button variant="contained" onClick={handleSave} color={'primary'}>Save</Button>
          </DialogActions>
        </Dialog>
    );
  }