import { useAppSelector } from "../../app/hooks";
import { selectEmail } from "../auth/authSlice";
import { Navigate, Outlet } from 'react-router-dom';

export function NotProtectodRoute() {
  const email = useAppSelector(selectEmail);
  if (email != null) {
    return (<Navigate to="/front/protected/patterns" />);
  }

  return (
    <Outlet />
  )

}
