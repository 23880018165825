import { Button, TextField } from "@mui/material";
import { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { EMAIL_REGEX } from "../common/commonApi";
import { loginAsync, selectEmailNonConfirmed } from "./authSlice";
import FormComponent from "./FormComponent";

export default () => {
  const [inputs, setInputs] = useState<
    {
      email: string | null,
      password: string | null
    }
  >({
    email: null,
    password: null,
  });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const emailNonConfirmed = useAppSelector(selectEmailNonConfirmed);

  if (emailNonConfirmed != null) {
    return (<Navigate to={"/front/signup-conf-code"} />);
  }

  const handleChange = (e: any) => {
    setInputs((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
  }
  const handleLogin = () => {
    if (inputs.email == null || inputs.password == null) {
      console.error('Input is empty')
      return
    }
    dispatch(loginAsync({ email: inputs.email, password: inputs.password }));
  }

  const emailIsTouched = inputs.email != null;
  const passwordIsTouched = inputs.password != null;
  const emailIsNotValid = emailIsTouched && !EMAIL_REGEX.test(inputs.email as string);
  const passwordIsEmpty = passwordIsTouched && (inputs.password as string).length === 0;

  return (
    <FormComponent formName={"Login"}>
      <TextField
        error={emailIsNotValid}
        margin="normal"
        variant="outlined"
        placeholder="Email"
        value={inputs.email}
        onChange={handleChange}
        name="email"
        helperText={emailIsNotValid && "Invalid email"}
      />
      <TextField
        error={passwordIsEmpty}
        margin="normal"
        variant="outlined"
        placeholder="Password"
        value={inputs.password}
        onChange={handleChange}
        name="password"
        type="password"
        helperText={passwordIsEmpty && "Password is empty"}
      />

      <Button
        onClick={() => navigate('/front/forgotpassword')}
        sx={{ marginTop: 0, borderRadius: 3 }}
      >
        Forgot password
      </Button>
      <Button
        sx={{ marginTop: 3, borderRadius: 3 }}
        variant="contained"
        color="primary"
        onClick={handleLogin}
        disabled={!emailIsTouched || !passwordIsTouched || emailIsNotValid || passwordIsEmpty}

      >
        {"Login"}
      </Button>
      <Button
        onClick={() => navigate("/front/signup")}
        sx={{ marginTop: 3, borderRadius: 3 }}
      >
        Go to Sign Up
      </Button>
    </FormComponent>
  );

}
