import { Box, Typography } from "@mui/material";


type Props = {
  formName: string,
  children: any[]
}

export default ({ formName, children }: Props) => {

  return (
    <Box
      display="flex"
      flexDirection={"column"}
      maxWidth={400}
      alignItems="center"
      justifyContent={"center"}
      margin="auto"
      marginTop={5}
      padding={3}
      borderRadius={5}
      boxShadow={"5px 5px 10px #ccc"}
      sx={{
        ":hover": {
          boxShadow: '10px 10px 20px #ccc'
        }
      }}
    >
      <Typography variant="h3" padding={3} textAlign="center">{formName}</Typography>
      {children}
    </Box>
  );
}
