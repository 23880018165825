export const getCookie = (cookieName: string) =>  document.cookie.split(';').map(function(el) {
      const [key,value] = el.split('=');
      if (key.trim() === cookieName) {
        return value;
      }
        return null
}).filter(val => val != null)[0];

export function parseCookie(name: string, cookies: string) {
  let cookie: {[key: string]: string} = {};
  cookies.split(';').forEach(function(el) {
    let [k,v] = el.split('=');
    cookie[k.trim()] = v;
  })
  return cookie[name];
}


export const EMAIL_REGEX: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
