import { PatternFormData, PatternTableRow } from "../auth/authSlice";

export async function fetchPatterns(): Promise<PatternTableRow[]> {
  const resp = await fetch(
    '/api/pattern',
  );
  return await resp.json() as PatternTableRow[];
}

export async function createPattern(data: PatternFormData): Promise<PatternTableRow[]> {
  const resp = await fetch('/api/pattern', {
    method: 'POST',
    body: JSON.stringify(data)
  });
  return await resp.json() as PatternTableRow[];
}

export async function updatePattern(data: PatternTableRow): Promise<PatternTableRow[]> {
  const resp = await fetch('/api/pattern', {
    method: 'POST',
    body: JSON.stringify(data)
  });
  return await resp.json() as PatternTableRow[];
}

export async function deletePattern(data: { patternScope: string, patternId: string }): Promise<PatternTableRow[]> {
  const resp = await fetch('/api/pattern', {
    method: 'DELETE',
    body: JSON.stringify(data)
  });
  return await resp.json() as PatternTableRow[];
}