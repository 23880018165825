import { AppBar, Box, Button, Toolbar, Typography } from "@mui/material";
import { Navigate, Outlet } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { logoutAsync, selectEmail } from "../auth/authSlice";

export function ProtectedRoute() {
  const email = useAppSelector(selectEmail);
  const dispatch = useAppDispatch();
  if (email == null) {
    return (<Navigate to="/front/login" />);
  }
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Koala Search App
          </Typography>
          <Button color="inherit" onClick={() => dispatch(logoutAsync())}>Logout</Button>
        </Toolbar>
      </AppBar>
      <Outlet />
    </Box>
  );

}
